import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Collapsible from "react-collapsible"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackBtn from "../components/backBtn"
import CollapsibleItem from "../components/collapsibleItemFaq"
import Coins from "../components/coinData"
import GP from "../images/GP.svg"
import ManagerImage from "../components/managerImage"
import AffPes from "../components/pes2021cta"

const ImageBlock = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  background: #b4b8c5;
  margin-bottom: 4rem;
  p {
    margin-top: 0.75rem;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`

const MoreContent = styled.div`
  margin: 3rem 0;
  ul {
    margin: 1rem 2rem;
  }
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    color: var(--main-black);
    font-size: 1.414rem;
    line-height: 1.6968rem;
    padding: 1rem 2rem;
    margin: 0;
    border-left: 2px solid var(--very-light-blue);
    svg {
      transition: transform 300ms;
    }
    &.is-open svg {
      transform: rotateZ(90deg);
    }
  }
  @media (max-width: 475px) {
    .Collapsible__trigger {
      font-size: 1.2rem;
    }
  }
`

const MoreOptions = styled.div`
  font-style: italic;
  p {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  li {
    font-size: 1rem;
    line-height: 1.38rem;
    padding-bottom: 0.5rem;
  }
`

const Manager2021 = ({ data }) => {
  const info = data.availableManagers.edges[0].node
  let managersWithSameFormation = data.managerFormation.edges
  managersWithSameFormation = managersWithSameFormation.filter(
    entry => entry.node.slug !== info.slug
  )
  let managersWithFluidFormation = data.managerFluid.edges
  managersWithFluidFormation = managersWithFluidFormation.filter(
    entry => entry.node.slug !== info.slug
  )
  let managersWithSameNationality = data.managerNationality.edges
  managersWithSameNationality = managersWithSameNationality.filter(
    entry =>
      entry.node.gameName !== info.gameName &&
      !entry.node.slug.match(/-?\d+\.?\d*$/)
  )
  const managerName = info.realName
    ? `${info.gameName} (${info.realName})`
    : info.gameName
  const moreThanOneEntry = data.initialManagerSlug.edges.length > 1
  const otherEntries = data.initialManagerSlug.edges.filter(
    entry => entry.node.slug !== info.slug
  )
  const nationalityStartsWithVowel = info.nationality.match(/^[aeiou]/i)
  const hasFluidFormation = info.fluid
  return (
    <Layout>
      <SEO
        title={`${managerName} in PES 2021`}
        description={`${managerName} is one of the managers in PES 2021 myClub. Have a look at his cost and his Offensive/Defensive Tactics.`}
      />
      <div className="container">
        <h1>{managerName} - Manager in PES 2021 myClub</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/managers/">Manager List</Link>
          </li>
          <li>2021</li>
          <li>{info.gameName}</li>
        </ul>
        <p>
          {managerName} is {nationalityStartsWithVowel ? "an" : "a"}{" "}
          {info.nationality} football manager. These are his Offensive and
          Defensive tactics in PES 2021 while in charge of {info.club}.
        </p>
        {hasFluidFormation && (
          <>
            <p>
              This manager has a Fluid Formation. He has 3 different Offensive
              tactics and 3 Defensive tactics, depending on the context of the
              game.
            </p>
            <p>
              To use all 3, Fluid Formation must be set to ON (you will find it
              below "Advanced Instructions").
            </p>
            <p>Use the slider below to view all possibilities.</p>
          </>
        )}
        {moreThanOneEntry && (
          <MoreOptions>
            <p>
              <strong>Note: </strong>This Manager has{" "}
              {otherEntries.length === 1
                ? "1 other formation"
                : `${otherEntries.length} more formations`}{" "}
              available. You may want to have a look at the following:
            </p>
            <ul>
              {otherEntries.map(entry => (
                <li key={entry.node.slug}>
                  <Link to={`/managers/2021/${entry.node.slug}/`}>
                    {entry.node.gameName} - {entry.node.formation}
                  </Link>
                </li>
              ))}
            </ul>
          </MoreOptions>
        )}
        <h2>Quick Info</h2>
        <p>
          <Coins value={info.coins} />
          <img src={GP} alt="Cost in GP" className="icon" />
          {info.gp.toLocaleString("de-DE")} <small>(if unlocked with GP)</small>
        </p>
        <p>
          <strong>Formation:</strong> {info.formation}
          <br />
          <strong>Management Skills:</strong> {info.managementSkills}
          <br />
          <strong>Adaptability:</strong> {info.adaptability}
        </p>
      </div>
      <AffPes />
      <div className="container">
        {hasFluidFormation ? (
          <>
            <h2>Offensive Tactic</h2>
            <Carousel showStatus={false}>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-offensive-tactic-1.jpg`}
                  alt={`${info.gameName} Offensive Tactic at Kick-off`}
                  loading="lazy"
                />
                <p>At Kick-off</p>
              </ImageBlock>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-offensive-tactic-2.jpg`}
                  alt={`${info.gameName} Offensive Tactic when in possession`}
                  loading="lazy"
                />
                <p>When in possession</p>
              </ImageBlock>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-offensive-tactic-3.jpg`}
                  alt={`${info.gameName} Offensive Tactic when out of possession`}
                  loading="lazy"
                />
                <p>When out of possession</p>
              </ImageBlock>
            </Carousel>
            <h2>Defensive Tactic</h2>
            <Carousel showStatus={false}>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-defensive-tactic-1.jpg`}
                  alt={`${info.gameName} Defensive Tactic at Kick-off`}
                  loading="lazy"
                />
                <p>At Kick-off</p>
              </ImageBlock>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-defensive-tactic-2.jpg`}
                  alt={`${info.gameName} Defensive Tactic when in possession`}
                  loading="lazy"
                />
                <p>When in possession</p>
              </ImageBlock>
              <ImageBlock>
                <img
                  src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/2021/${info.slug}-defensive-tactic-3.jpg`}
                  alt={`${info.gameName} Defensive Tactic when out of possession`}
                  loading="lazy"
                />
                <p>When out of possession</p>
              </ImageBlock>
            </Carousel>
          </>
        ) : (
          <>
            <h2>Offensive Tactic</h2>
            <ManagerImage
              slug={info.slug}
              gameName={info.gameName}
              realName={info.realName}
              tactic="offensive-tactic"
              tacticAlt="Offensive"
              year="2021"
            />
            <h2>Defensive Tactic</h2>
            <ManagerImage
              slug={info.slug}
              gameName={info.gameName}
              realName={info.realName}
              tactic="defensive-tactic"
              tacticAlt="Defensive"
              year="2021"
            />
          </>
        )}
        <MoreContent>
          {managersWithSameFormation.length > 1 && (
            <Collapsible
              trigger={
                <CollapsibleItem
                  text={`More managers using ${info.formation}`}
                />
              }
              contentContainerTagName="div"
            >
              <ul className="grid-list">
                {managersWithSameFormation.map(entry => (
                  <li key={entry.node.slug}>
                    <Link to={`/managers/2021/${entry.node.slug}/`}>
                      {entry.node.gameName}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapsible>
          )}
          {managersWithSameNationality.length >= 1 && (
            <Collapsible
              trigger={
                <CollapsibleItem text={`Other ${info.nationality} managers`} />
              }
              contentContainerTagName="div"
            >
              <ul className="grid-list">
                {managersWithSameNationality.map(entry => (
                  <li key={entry.node.slug}>
                    <Link to={`/managers/2021/${entry.node.slug}/`}>
                      {entry.node.gameName}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapsible>
          )}
          {hasFluidFormation && managersWithFluidFormation.length > 1 && (
            <Collapsible
              trigger={
                <CollapsibleItem text={`Managers with Fluid Formation`} />
              }
              contentContainerTagName="div"
            >
              <ul className="grid-list">
                {managersWithFluidFormation.map(entry => (
                  <li key={entry.node.slug}>
                    <Link to={`/managers/2021/${entry.node.slug}/`}>
                      {entry.node.gameName}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapsible>
          )}
        </MoreContent>
        <BackBtn
          text="Back to Manager List"
          destination="/managers/#manager-list"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query managers2021(
    $slug: String
    $firstOfType: String
    $formation: String
    $nationality: String
  ) {
    availableManagers: allManager2021(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          gameName
          realName
          club
          nationality
          gp
          coins
          formation
          managementSkills
          adaptability
          fluid
        }
      }
    }
    initialManagerSlug: allManager2021(
      filter: { slug: { regex: $firstOfType } }
    ) {
      edges {
        node {
          slug
          gameName
          formation
        }
      }
    }
    managerFormation: allManager2021(
      sort: { fields: gameName, order: ASC }
      filter: { formation: { eq: $formation } }
    ) {
      edges {
        node {
          slug
          gameName
        }
      }
    }
    managerFluid: allManager2021(
      sort: { fields: gameName, order: ASC }
      filter: { fluid: { eq: true } }
    ) {
      edges {
        node {
          slug
          gameName
        }
      }
    }
    managerNationality: allManager2021(
      sort: { fields: gameName, order: ASC }
      filter: { nationality: { eq: $nationality } }
    ) {
      edges {
        node {
          slug
          gameName
        }
      }
    }
  }
`

export default Manager2021
